body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-datatable .p-datatable-header {
  padding-top: 0.5rem !important;
  padding-right: 1rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 1rem !important;
}

.btnTabla{
  height: 1.9rem !important;
  width: 1.9rem !important;
}


.p-inputtext {
  font-size: 0.875rem !important;
  padding: 0.25rem 0.4rem !important;
}

.p-field > label {
  font-size: 13px;
  padding-left: 4px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.1rem 1rem !important;
}



.formSearch {
  padding: 1rem !important;
}

.marginLR {
  margin-left: 5px  !important;
  margin-right: 25px  !important;
}

.center {
  text-align: center;
}

.centerMargin {
  margin-left: auto;
  margin-right: auto;
}

.p-datatable .p-datatable-header {
  font-weight: unset  !important;
  font-size: 14px;
}

.p-calendar .p-datepicker {
  width: 330px;
}
