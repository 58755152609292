.btnMenu {
    background: #f8f9fa !important;
    color: #495057 !important;
    border: none !important;
  }

.p-sidebar .p-sidebar-header {
    padding: 0rem !important;
}

.p-sidebar .p-sidebar-content {
    padding: 0rem !important;
}